import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { QuestionBase } from '../models/question-base';

@Injectable()
export class QuestionControlService {
	toFormGroup(questionsArray: QuestionBase<any>[]): UntypedFormGroup {
		const group: any = {};

		questionsArray.forEach((question: QuestionBase<any>) => {
			if (question.required) {
				question.validators.push(Validators.required);
			}

			group[question.key] = question.customControlInit
				? question.customControlInit()
				: new UntypedFormControl({ value: question.value != null ? question.value : '', disabled: question.disabled }, question.validators);

			if (question.onChange) {
				group[question.key].valueChanges.subscribe((value: any) => {
					question.onChange(value);
				});
			}
		});

		return new UntypedFormGroup(group);
	}

	toArray(questions: any): QuestionBase<any>[] {
		return Object.keys(questions).map((questionKey: string): QuestionBase<any> => questions[questionKey]);
	}
}

<div id="date-container">
	<div #calender id="calendar">
		{{ text }}
		<span class="spacer"></span>
		<i id="icon" class="far fa-calendar-alt"></i>
	</div>
	<div id="type">{{ this.currentDateRange.type }}</div>

	<div *ngIf="showArrows" id="buttons-left-right">
		<button
			[disabled]="disabled"
			type="button"
			class="align-top btn btn--border-transparent small-padding date-picker-arrow"
			(click)="changeDate(false, false)">
			<i class="fa fa-caret-left"></i>
		</button>
		<button
			[disabled]="disabled"
			type="button"
			class="align-top btn btn--border-transparent small-padding date-picker-arrow"
			(click)="changeDate(true, false)">
			<i class="fa fa-caret-right"></i>
		</button>
	</div>
	<div *ngIf="showFrequency" class="dropdown button-selector" placement="bottom-right">
		<button
			id="selectTypeFrequency"
			[disabled]="disabled"
			class="dropdown-toggle btn btn--primary select-type"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false">
			{{ currentDateRange.frequencyTypeDesc }}
		</button>
		<div class="dropdown-menu" aria-labelledby="selectTypeFrequency">
			<button
				*ngFor="let frequencyType of frequencyTypes; trackBy: trackByFrequencyType"
				class="dropdown-item"
				(click)="selectFrequencyType(frequencyType)">
				{{ frequencyType.label }}
			</button>
		</div>
	</div>
</div>

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CmUserService } from './cm-user.service';
import { CmUser } from '../cm-user.model';
import { RolePermissionBackendEnum, RolePermissionFrontendEnum, RolePermissionFrontendModuleEnum } from '../enums';
import { UserPermissions } from '../models/permissions';

@Injectable({
	providedIn: 'root',
})
export class UserPermissionsService implements OnDestroy {
	frontendPermissions: UserPermissions;

	private _currentUser: CmUser;
	private _subscriptions = new Subscription();
	private _permissionsSubject = new BehaviorSubject<UserPermissions>(null);

	get permissions$(): Observable<UserPermissions> {
		return this._permissionsSubject.asObservable();
	}

	constructor(private _cmUserService: CmUserService) {
		this._subscriptions.add(
			this._cmUserService.currentUser$.subscribe((user: CmUser) => {
				this._currentUser = user;
				this.assignFrontendPermissions();
				this._permissionsSubject.next(this.frontendPermissions);
			})
		);
	}

	ngOnDestroy(): void {
		this._permissionsSubject.complete();
		this._subscriptions.unsubscribe();
	}

	hasModulePermission(value: RolePermissionFrontendModuleEnum): boolean {
		return this._currentUser.hasModulePermission(value);
	}

	hasBackendPermission(value: RolePermissionBackendEnum): boolean {
		return this._currentUser.hasPermission(value);
	}

	hasBackendPermissions(values: RolePermissionBackendEnum[]): boolean {
		return this._currentUser.hasAllPermissions(values);
	}

	private assignFrontendPermissions(): void {
		this.frontendPermissions = {
			components: {
				isFeedersRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ComponentsFeedersRead),
				isFeedersWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ComponentsFeedersWrite),
				isFingersRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ComponentsFingersRead),
				isFingersWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ComponentsFingersWrite),
				isItemsRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ComponentsItemsRead),
				isItemsWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ComponentsItemsWrite),
				isToolsRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ComponentsToolsRead),
				isToolsWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ComponentsToolsWrite),
				isItemClassesRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ComponentsItemClassesRead),
				isItemClassesWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ComponentsItemClassesWrite),
			},
			isDashboard: this._currentUser.hasPermission(RolePermissionFrontendEnum.Dashboard),
			isMachinesAlarms: this._currentUser.hasPermission(RolePermissionFrontendEnum.MachinesAlarms),
			management: {
				isChangeClients: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementChangeClients),
				isClientsRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementClientsRead),
				isClientsWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementClientsWrite),
				isDevicesRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementDevicesRead),
				isDevicesWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementDevicesWrite),
				isFloorplanRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementFloorplanRead),
				isFloorplanWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementFloorplanWrite),
				isLinesRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementLinesRead),
				isLinesWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementLinesWrite),
				isLocationsRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementLocationsRead),
				isLocationsWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementLocationsWrite),
				isMachineStatesRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementMachineStatesRead),
				isMachineStatesWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementMachineStatesWrite),
				isNotificationsRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementNotificationsRead),
				isNotificationsWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementNotificationsWrite),
				isOperationStatusesRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementOperationStatusesRead),
				isOperationStatusesWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementOperationStatusesWrite),
				isRolesRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementRolesRead),
				isRolesWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementRolesWrite),
				isSensorsRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementSensorsRead),
				isSensorsWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementSensorsWrite),
				isMachinesRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementMachinesRead),
				isMachinesWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementMachinesWrite),
				isShiftsRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementShiftsRead),
				isShiftsWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementShiftsWrite),
				isUsersPasswordReset: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementUsersPasswordReset),
				isUsersRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementUsersRead),
				isUsersWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementUsersWrite),
				isZonesRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementZonesRead),
				isZonesWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.ManagementZonesWrite),
			},
			monitoring: {
				isAlarmsDeck: this._currentUser.hasPermission(RolePermissionFrontendEnum.MonitoringAlarmsDeck),
				isFloorplan: this._currentUser.hasPermission(RolePermissionFrontendEnum.MonitoringFloorplan),
				isSensorsAlarms: this._currentUser.hasPermission(RolePermissionFrontendEnum.MonitoringSensorsAlarms),
				isTrendViewer: this._currentUser.hasPermission(RolePermissionFrontendEnum.MonitoringTrendViewer),
			},
			recipes: {
				isActionsRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.RecipesActionsRead),
				isActionsWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.RecipesActionsWrite),
				isAlgorithmsRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.RecipesAlgorithmsRead),
				isAlgorithmsWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.RecipesAlgorithmsWrite),
				isGeneratorRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.RecipesGeneratorRead),
				isGeneratorSend: this._currentUser.hasPermission(RolePermissionFrontendEnum.RecipesGeneratorSend),
				isGeneratorWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.RecipesGeneratorWrite),
			},
			reports: {
				isAggregate: this._currentUser.hasPermission(RolePermissionFrontendEnum.ReportsAggregate),
				isProduction: this._currentUser.hasPermission(RolePermissionFrontendEnum.ReportsProduction),
				isTechnical: this._currentUser.hasPermission(RolePermissionFrontendEnum.ReportsTechnical),
				isOverallDashboard: this._currentUser.hasPermission(RolePermissionFrontendEnum.ReportOverallDashboard),
				isShiftDashboard: this._currentUser.hasPermission(RolePermissionFrontendEnum.ReportShiftDashboard),
			},
			updates: {
				isRead: this._currentUser.hasPermission(RolePermissionFrontendEnum.UpdatesRead),
				isSend: this._currentUser.hasPermission(RolePermissionFrontendEnum.UpdatesSend),
				isWrite: this._currentUser.hasPermission(RolePermissionFrontendEnum.UpdatesWrite),
			},
		};
	}
}

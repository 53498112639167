import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as daterangepicker from 'daterangepicker';
import * as moment from 'moment';
import { DateRangeTypeEnum } from '../../enums/date-range-type.enum';
import { FrequencyTypeEnum } from '../../enums/frequency-type.enum';
import { IDateRange } from '../../models/date-range';
import { FrequencyType } from '../../models/frequency-type.model';

@Component({
	selector: 'fitech-workspace-date-time-range-picker-v2',
	templateUrl: './date-time-range-picker-v2.component.html',
	styleUrls: ['./date-time-range-picker-v2.component.scss'],
})
export class DateTimeRangePickerV2Component implements OnInit {
	@Input() initialDateRange: IDateRange;
	@Input() format = 'YYYY.MM.DD HH:mm:ss';
	@Input() showFrequency = false;
	@Input() showArrows = true;
	@Input() disabled = false;

	@Output() dateRangeChanged = new EventEmitter<IDateRange>();
	@Output() frequencyChanged = new EventEmitter<IDateRange>();

	@ViewChild('calender', { static: true }) calender: ElementRef;

	currentDateRange: IDateRange = { from: null, to: null, type: null };
	text: string;

	frequencyTypes: FrequencyType[] = [
		{ type: FrequencyTypeEnum.AllData, label: 'All data', frequencyInSeconds: 0 },
		{ type: FrequencyTypeEnum.EveryMinute, label: 'Every 1 minute', frequencyInSeconds: 60 },
		{ type: FrequencyTypeEnum.EveryHour, label: 'Every 1 hour', frequencyInSeconds: 60 * 60 },
		{ type: FrequencyTypeEnum.EveryDay, label: 'Every 1 day', frequencyInSeconds: 24 * 60 * 60 },
	];

	ngOnInit(): void {
		if (!this.initialDateRange) {
			this.initialDateRange = {
				from: moment().startOf('day').toDate(),
				to: moment().startOf('day').add(1, 'days').toDate(),
				type: DateRangeTypeEnum.LastDay,
			};
		}

		const dp = new daterangepicker(
			this.calender.nativeElement,
			{
				autoApply: true,
				showDropdowns: true,
				timePicker: true,
				timePicker24Hour: true,
				timePickerSeconds: true,
				alwaysShowCalendars: true,
				autoUpdateInput: true,
				showCustomRangeLabel: false,
				isCustomDate: (date: any): string => {
					if (moment().format('DD MMMM YYYY') === date.format('DD MMMM YYYY')) {
						return 'data-range-picker-today';
					}
				},
				startDate: this.initialDateRange.from,
				endDate: this.initialDateRange.to,
				locale: {
					format: this.format,
				},
				ranges: {
					'Last hour': [moment().add(-1, 'hour').toDate(), moment().toDate()],
					'Last day': [moment().startOf('day').toDate(), moment().startOf('day').add(1, 'day').toDate()],
					'Last week': [moment().startOf('day').add(-6, 'day').toDate(), moment().startOf('day').add(1, 'day').toDate()],
					'Last month': [moment().startOf('day').add(-29, 'day').toDate(), moment().startOf('day').add(1, 'day').toDate()],
					'Last quarter': [moment().startOf('day').add(-89, 'day').toDate(), moment().startOf('day').add(1, 'day').toDate()],
				},
			},
			(start: any, end: any, label: any) => {
				this.setCurrentDateRange(start.toDate(), end.toDate(), label);
			}
		);
		this.setCurrentDateRange(this.initialDateRange.from, this.initialDateRange.to, this.initialDateRange.type);
		this.setCurrentDateText();
	}

	changeDate(forward: boolean, adjustFrequency: boolean): void {
		if (!this.currentDateRange.from || !this.currentDateRange.to) {
			return;
		}

		const oldFrom = this.currentDateRange.from.getTime();
		const oldTo = this.currentDateRange.to.getTime();
		const milliseconds = oldTo - oldFrom;

		if (forward) {
			this.setCurrentDateRange(new Date(oldTo), new Date(oldTo + milliseconds), DateRangeTypeEnum.Custom, adjustFrequency);
		} else {
			this.setCurrentDateRange(new Date(oldFrom - milliseconds), new Date(oldFrom), DateRangeTypeEnum.Custom, adjustFrequency);
		}
	}

	selectFrequencyType(frequencyType: FrequencyType, emitEvent: boolean = true): void {
		this.currentDateRange.frequencyTypeDesc = frequencyType.label;
		this.currentDateRange.frequency = frequencyType.frequencyInSeconds;
		this.currentDateRange.frequencyType = frequencyType.type;

		if (emitEvent) {
			this.frequencyChanged.emit(this.currentDateRange);
		}
	}

	setCurrentDateRange(from: Date, to: Date, type: string, adjustFrequency: boolean = true): void {
		this.currentDateRange.type = type || DateRangeTypeEnum.Custom;

		switch (this.currentDateRange.type) {
			case DateRangeTypeEnum.LastHour:
				this.currentDateRange.from = moment().add(-1, 'hour').toDate();
				this.currentDateRange.to = moment().toDate();
				if (adjustFrequency) {
					this.setFrequencyByType(FrequencyTypeEnum.EveryMinute, false);
				}
				break;
			case DateRangeTypeEnum.LastDay:
			case DateRangeTypeEnum.LastWeek:
			case DateRangeTypeEnum.LastMonth:
			case DateRangeTypeEnum.LastQuarter:
				this.currentDateRange.from = from;
				this.currentDateRange.to = to;
				if (adjustFrequency) {
					this.setFrequencyByType(FrequencyTypeEnum.EveryHour, false);
				}
				break;
			case DateRangeTypeEnum.Custom:
				this.currentDateRange.from = from;
				this.currentDateRange.to = to;
				this.setFrequencyForCustomDateRange(from, to);
				break;
		}
		this.setCurrentDateText();
		this.dateRangeChanged.emit(this.currentDateRange);
	}

	//#region Private methods
	private setFrequencyByType(type: FrequencyTypeEnum, emitEvent: boolean = true): void {
		const selectedFrequencyType: FrequencyType = this.frequencyTypes.find((frequencyType: FrequencyType): boolean => frequencyType.type === type);
		this.selectFrequencyType(selectedFrequencyType, emitEvent);
	}

	private setFrequencyForCustomDateRange(from: Date, to: Date): void {
		if (!from || !to) {
			return;
		}

		const oneDayInMsc = 86400000;
		const dateRangeDiffMiliseconds: number = to.getTime() - from.getTime();
		if (dateRangeDiffMiliseconds < oneDayInMsc) {
			this.setFrequencyByType(FrequencyTypeEnum.EveryMinute, false);
			return;
		}

		this.setFrequencyByType(FrequencyTypeEnum.EveryHour, false);
	}

	private setCurrentDateText(): void {
		this.text = `${moment(this.currentDateRange.from).format(this.format)} - ${moment(this.currentDateRange.to).format(this.format)}`;
	}
	//#endregion
}

<form class="form" [formGroup]="form" (ngSubmit)="onSave()">
	<div class="form__controls" cdkScrollable>
		<div *ngFor="let question of questionsArray" class="form__row">
			<div *ngIf="question.visible" [hidden]="question.isHidden" class="dynamic-input-form">
				<div class="dynamic-input-form" [ngSwitch]="question.controlType">
					<fitech-workspace-form-question
						*ngSwitchCase="'textbox'"
						[question]="question"
						[form]="form"
						[showUndoButton]="showUndoButton">
					</fitech-workspace-form-question>
					<fitech-workspace-autocomplete-md
						*ngSwitchCase="'dropdown'"
						[form]="form"
						[question]="question"
						[showUndoButton]="showUndoButton">
					</fitech-workspace-autocomplete-md>
					<fitech-workspace-multi-dropdown-md *ngSwitchCase="'selectlist'" [form]="form" [question]="question"></fitech-workspace-multi-dropdown-md>
					<fitech-workspace-checkbox-md
						*ngSwitchCase="'checkbox'"
						[form]="form"
						[question]="question"
						[showUndoButton]="showUndoButton">
					</fitech-workspace-checkbox-md>
					<fitech-workspace-fileuploader-md
						*ngSwitchCase="'fileUploader'"
						[form]="form"
						[question]="question"
						[showUndoButton]="showUndoButton">
					</fitech-workspace-fileuploader-md>
					<fitech-workspace-dynamic-form-color-picker
						*ngSwitchCase="'colorPicker'"
						[form]="form"
						[question]="question"
						[showUndoButton]="showUndoButton">
					</fitech-workspace-dynamic-form-color-picker>
					<fitech-workspace-switch-md *ngSwitchCase="'switch'" [form]="form" [question]="question"></fitech-workspace-switch-md>
					<div *ngSwitchCase="'custom'">
						<ng-template addDynamicComponent [componentId]="question.key"></ng-template>
					</div>
				</div>
				<div class="errorMessage" *ngIf="getErrorMsg(question.key).length > 0">
					{{ getErrorMsg(question.key) | translate }}
				</div>
			</div>
		</div>
	</div>
	<div class="form__buttons">
		<button type="button" class="btn btn--tertiary btn--tertiary-wider" (click)="onCancel()">{{ 'Cancel' | translate }}</button>
		<button type="submit" class="btn btn--primary btn--primary-wider" [disabled]="!canSave">
			<i *ngIf="isSubmitting" class="fas fa-sync fa-spin refresh-spinner icon icon--mr-4"></i>
			{{ 'Save' | translate }}
		</button>
	</div>
</form>

<div *ngIf="payload" class="form-row">
	<strong>Saved the following values</strong>
	<br />
	{{ payload }}
</div>

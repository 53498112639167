export enum RolePermissionFrontendEnum {
	ComponentsFeedersRead = 'Permissions.Frontend.Components.Feeders.Read',
	ComponentsFeedersWrite = 'Permissions.Frontend.Components.Feeders.Write',
	ComponentsFingersRead = 'Permissions.Frontend.Components.Fingers.Read',
	ComponentsFingersWrite = 'Permissions.Frontend.Components.Fingers.Write',
	ComponentsItemsRead = 'Permissions.Frontend.Components.Items.Read',
	ComponentsItemsWrite = 'Permissions.Frontend.Components.Items.Write',
	ComponentsToolsRead = 'Permissions.Frontend.Components.Tools.Read',
	ComponentsToolsWrite = 'Permissions.Frontend.Components.Tools.Write',
	ComponentsItemClassesRead = 'Permissions.Frontend.Components.ItemClasses.Read',
	ComponentsItemClassesWrite = 'Permissions.Frontend.Components.ItemClasses.Write',
	Dashboard = 'Permissions.Frontend.Dashboard',
	MachinesAlarms = 'Permissions.Frontend.MachinesAlarms',
	ManagementChangeClients = 'Permissions.Frontend.Management.ChangeClients',
	ManagementClientsRead = 'Permissions.Frontend.Management.Clients.Read',
	ManagementClientsWrite = 'Permissions.Frontend.Management.Clients.Write',
	ManagementDevicesRead = 'Permissions.Frontend.Management.Devices.Read',
	ManagementDevicesWrite = 'Permissions.Frontend.Management.Devices.Write',
	ManagementFloorplanRead = 'Permissions.Frontend.Management.Floorplan.Read',
	ManagementFloorplanWrite = 'Permissions.Frontend.Management.Floorplan.Write',
	ManagementLinesRead = 'Permissions.Frontend.Management.Lines.Read',
	ManagementLinesWrite = 'Permissions.Frontend.Management.Lines.Write',
	ManagementLocationsRead = 'Permissions.Frontend.Management.Locations.Read',
	ManagementLocationsWrite = 'Permissions.Frontend.Management.Locations.Write',
	ManagementMachineStatesRead = 'Permissions.Frontend.Management.MachineStates.Read',
	ManagementMachineStatesWrite = 'Permissions.Frontend.Management.MachineStates.Write',
	ManagementNotificationsRead = 'Permissions.Frontend.Management.Notifications.Read',
	ManagementNotificationsWrite = 'Permissions.Frontend.Management.Notifications.Write',
	ManagementOperationStatusesRead = 'Permissions.Frontend.Management.OperationStatuses.Read',
	ManagementOperationStatusesWrite = 'Permissions.Frontend.Management.OperationStatuses.Write',
	ManagementRolesRead = 'Permissions.Frontend.Management.Roles.Read',
	ManagementRolesWrite = 'Permissions.Frontend.Management.Roles.Write',
	ManagementSensorsRead = 'Permissions.Frontend.Management.Sensors.Read',
	ManagementSensorsWrite = 'Permissions.Frontend.Management.Sensors.Write',
	ManagementMachinesRead = 'Permissions.Frontend.Management.Machines.Read',
	ManagementMachinesWrite = 'Permissions.Frontend.Management.Machines.Write',
	ManagementShiftsRead = 'Permissions.Frontend.Management.Shifts.Read',
	ManagementShiftsWrite = 'Permissions.Frontend.Management.Shifts.Write',
	ManagementUsersPasswordReset = 'Permissions.Frontend.Management.Users.PasswordReset',
	ManagementUsersRead = 'Permissions.Frontend.Management.Users.Read',
	ManagementUsersWrite = 'Permissions.Frontend.Management.Users.Write',
	ManagementZonesRead = 'Permissions.Frontend.Management.Zones.Read',
	ManagementZonesWrite = 'Permissions.Frontend.Management.Zones.Write',
	MonitoringAlarmsDeck = 'Permissions.Frontend.Monitoring.AlarmsDeck',
	MonitoringFloorplan = 'Permissions.Frontend.Monitoring.Floorplan',
	MonitoringSensorsAlarms = 'Permissions.Frontend.Monitoring.SensorsAlarms',
	MonitoringTrendViewer = 'Permissions.Frontend.Monitoring.TrendViewer',
	RecipesActionsRead = 'Permissions.Frontend.Recipes.Actions.Read',
	RecipesActionsWrite = 'Permissions.Frontend.Recipes.Actions.Write',
	RecipesAlgorithmsRead = 'Permissions.Frontend.Recipes.Algorithms.Read',
	RecipesAlgorithmsWrite = 'Permissions.Frontend.Recipes.Algorithms.Write',
	RecipesGeneratorRead = 'Permissions.Frontend.Recipes.Generator.Read',
	RecipesGeneratorSend = 'Permissions.Frontend.Recipes.Generator.Send',
	RecipesGeneratorWrite = 'Permissions.Frontend.Recipes.Generator.Write',
	ReportsAggregate = 'Permissions.Frontend.Reports.Aggregate',
	ReportsProduction = 'Permissions.Frontend.Reports.Production',
	ReportsTechnical = 'Permissions.Frontend.Reports.Technical',
	ReportShiftDashboard = 'Permissions.Frontend.Reports.ShiftDashboard',
	ReportOverallDashboard = 'Permissions.Frontend.Reports.OverallDashboard',
	UpdatesRead = 'Permissions.Frontend.Updates.Read',
	UpdatesSend = 'Permissions.Frontend.Updates.Send',
	UpdatesWrite = 'Permissions.Frontend.Updates.Write',
}
